/* global  config, angular, moment, PDFJS, Promise, parseInt, FormData  */

'use strict';
angular.module('managerApp').controller('ClientsController', (
        $rootScope, $scope, $timeout, $location, $routeParams, $mdDialog, $cookies,
        $route, toast, clients, documents, procedureTools, procedureService, passwords) => {

//------------------------------------------------------------------------------

    const path = $location.path();
    $scope.config = config;
    $scope.gData = gData;
    
    $scope.clientId = $routeParams.id;
    $scope.client = null;
    $scope.clients = null;
    $scope.isItem = typeof $scope.clientId !== 'undefined';
    $scope.isEdit = typeof $scope.clientId !== 'undefined';

    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;
    const TEMPLATES_DIR = config.app.templatesDir;
    $scope.documentTypes = procedureTools.getDocumentTypes();
    $scope.addressWayTypes = $scope.gData.addressWayTypes;
    $scope.clientTypes = ['partenaire','client-particulier','client-professionnel','professionnel']
    $scope.getClientType = function(){
        const type = path.split('/');
        return ((type.length > 2 && $scope.clientTypes.includes(type[2])) ? type[2] : '');
    }
    $scope.pageTitle = $scope.getClientType();
    $scope.fixedFilter = $scope.pageTitle ? {type: $scope.pageTitle} : {};

    $scope.client = {type:$scope.getClientType()}
    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: $cookies.get('pagination.clients.itemsPerPage') || 25,
        totalItems: 0,
        pageChanged: function () {
            $cookies.put('pagination.clients.itemsPerPage', this.itemsPerPage);
            getClients()
        }
    };

    $scope.clientProceduresPagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: $cookies.get('pagination.clients.procedures.itemsPerPage') || 25,
        totalItems: 0,
        pageChanged: function () {
            $cookies.put('pagination.clients.procedures.itemsPerPage', this.itemsPerPage);
            getProcedures();
        }
    };

    $rootScope.$on('get-client-procedures', function (e, type) {
        $scope.clientProceduresPagination.filters = e.targetScope.search.filters;
        getProcedures();
    });

//------------------------------------------------------------------------------

    $scope.getWayType = (query) => {
        return query ? $scope.addressWayTypes.filter(createFilterFor(query)) : $scope.addressWayTypes;
    };
    function createFilterFor(query = '') {
        query = query.toLowerCase();
        return (w) => w.short.toLowerCase().indexOf(query) === 0 || w.long.toLowerCase().indexOf(query) === 0;
    }
    $scope.selectedWayTypeChange = (item = null) => {
        if (item !== null && typeof item !== 'string') {
            $scope.client.address.wayType = item.short;
        }//
    };

    $scope.statuses = [
        {
            id: 'all',
            name: 'Tous les statuts'
        },
        {
            id: 'archived',
            name: 'Archivé'
        },
        {
            id: 'awaiting-data',
            name: 'En attente des données'
        },
        {
            id: 'awaiting-documents-signature',
            name: 'En attente de signature des documents'
        },
        {
            id: 'awaiting-documents-upload',
            name: 'En attente de téléversement des documents'
        },
        {
            id: 'awaiting-payment',
            name: 'En attente de paiement'
        },
        {
            id: 'documents-signed',
            name: 'Documents signés'
        },
        {
            id: 'sent-to-tms',
            name: 'Démarche envoyée à TMS'
        },
        {
            id: 'tms-ready',
            name: 'Prêt pour l\'envoi à TMS'
        }
    ];

//------------------------------------------------------------------------------

    function initClientAddresses(client) {
        let common, billing;
        for (let i = 0; i < client.Addresses.length; i++) {
            let address = client.Addresses[i];
            if (address.type === 'common') {
                common = address;
            }
            if (address.type === 'billing') {
                billing = address;
            }
            address.isBilling = ['billing', 'common'].indexOf(address.type) > -1;
            address.isShipping = ['shipping', 'common'].indexOf(address.type) > -1;
        }
        return common ? common : billing;
    }

    $scope.closeDialog = () => {
        $mdDialog.hide();
    };
    $scope.showPrerenderedDialog = function (ev) {
        $mdDialog.show({
            contentElement: '#companyInfosPopup',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true
        });
    };

    $scope.getCompanyInfoLabel = (info, order = false) => {
        let list = [
            'deno',
            'dirig',
            'adresse',
            'codepostal',
            'commune',
            'greffe',
            'siren',
            'psiret',
            'apetexte',
            'ape',
            'formejur',
            'dateimmat',
            'social',
            'url'
        ];
        if (order) {
            return list.indexOf(info);
        }
        switch (info.toLowerCase()) {
            case 'deno':
                return 'Raison social';
            case 'enseigne':
                return 'Nom commercial';
            case 'psiret':
                return 'Numéro SIRET';
            case 'siren':
                return 'Numéro SIREN';
            case 'adresse':
                return 'Adresse';
            case 'codepostal' :
                return 'Code postal';
            case 'commune':
                return 'Ville';
            case 'ape':
                return 'Code de l’activité';
            case 'apetexte':
                return 'Intitulé complet de l\'activité';
            case 'dateimmat':
                return 'Date d’immatriculation';
            case 'url':
                return 'Page sur societe.com';
            case 'dcren':
                return 'Date création';
            case 'greffe':
                return 'RCS';
            case 'social':
                return 'Capital social';
            case 'formejur':
                return 'Forme juridique';
            case 'nationalite':
                return 'Pays';
            case 'dirig':
                return 'Dirigeant';
            default:
                return ' - ' + info;
        }//
    };

//------------------------------------------------------------------------------

    function getClient(id) {
        if (!id) {
            return;
        }
        clients.get({id: id}).then((client) => {

            client.name = client.firstname + ' ' + client.lastname;
            client.address = initClientAddresses(client);
            client.isPro = client.type !== 'client-particulier';
            client.isProfessional = client.type === 'professionnel';
            client.isProAuto = client.isProfessional;
            client.isPartner = client.type === 'partenaire';
            client.companyInfos = client.companyInfos ? JSON.parse(client.companyInfos) : null;
            $scope.tabletPartner = client.TabletPartner
            $scope.terminalPartner = client.TerminalPartner
            $scope.webAppPartner = client.webAppPartner

            if (client.siren !== null) {
                let link = 'https://annuaire-entreprises.data.gouv.fr';
                let type = 'entreprise';
                if ((client.siren + '').length === 14) {
                    type = 'etablissement';
                }
                client.societeLink = link + '/' + type + '/' + client.siren;
            }

            if (client.Addresses.length > 0) {
                client.hasAddress = true;
                client.Address = client.Addresses[0];
            }

            //client.address.wayType = 'chem';

            if (client.Procedures && client.Procedures.length === 1) {
                client.procedure = client.Procedures[0];
                $scope.procedure = client.Procedures[0];
                procedureTools.initDocuments($scope);
            }

            if (client.companyInfos) {
                client.companyInfosOrdered = [];
                delete client.companyInfos.main.etablissement;
                delete client.companyInfos.main.bilan;
                delete client.companyInfos.main.normcommune;
                delete client.companyInfos.main.evenement;
                for (let attr in client.companyInfos.main) {
                    let name = attr, index,
                            value = client.companyInfos.main[attr];
                    if (Array.isArray(value)) {
                        value = value[0];
                    }
                    if (attr === 'no') {
                        name = value.$.type;
                    }
                    if (attr === 'capital') {
                        name = value.$.typecap;
                    }
                    if (attr === 'url') {
                        value = '<a href="' + value + '" target="_blank">' +
                                value + '</a>';
                    }
                    if (attr === 'dirig' && value.detdirig) {
                        value = value.detdirig[0];
                    }
                    if (value._) {
                        value = value._ + (attr === 'capital' ? ' €' : '');
                    }
                    index = $scope.getCompanyInfoLabel(name, true);
                    if (!isNaN(index) && index > -1) {
                        client.companyInfosOrdered[index] = {
                            name: $scope.getCompanyInfoLabel(name),
                            value: value
                        };
                    }
                }
            }

            /*$scope.uploadReminder = false;
             $scope.signReminder = false;
             for (let i = 0; i < client.Documents.length; i++) {
             let document = client.Documents[i];
             $scope.uploadReminder = $scope.uploadReminder || document.status === 'to_upload';
             $scope.signReminder = $scope.signReminder || document.status === 'to_sign';
             }*/

            $scope.client = client;

            if ($scope.isEdit) {
                $scope.originalClient = Object.assign({}, client);
                $scope.originalClient.address = Object.assign({}, client.address);
            }

            $scope.$apply();
        }).catch((err) => {
            toast.show('Compte client introuvable', 'error').then(() => {
                $location.path('/clients');
            });
        });
    }

//------------------------------------------------------------------------------

    $scope.checkDocument = (document) => {
        if (document.concordance) {
            let msg = 'Vous ne pouvez pas valider ce document car il ' +
                    'est nécessaire de vérifier sa concordance par ' +
                    'rapport ' + document.concordance.documentLabel + ' ',
                    l = msg.length;
            if (document.concordance.document === null) {
                msg += 'qui n\'a pas encore été téléversé.';
            } else if (document.concordance.document.status !== 'document-accepted') {
                msg += 'qui n\'a pas encore été validé.';
            }
            if (msg.length > l) {
                toast.show(msg, 'error', true);
                return;
            }
        }
        document.validation = procedureTools.getDocValidationQuestions(document);
        procedureTools.openDocumentsViewer(document, $scope);
    };

    $scope.getClients = getClients;

    $scope.getAddressType = (addressType) => {
        switch (addressType) {
            case 'common':
                return 'Par défaut';
            case 'billing':
                return 'Facturation';
            case 'shipping':
                return 'Livraison';
            default:
                return '';
        }
    };

    $scope.plates = {
        getPlateAttrLabel: (attr) => {
            switch (attr) {
                case 'vehicleRegistrationNum':
                    return 'N° d\'immatriculation';
                case 'vehicleType':
                    return 'Type du véhicule';
                case 'department':
                    return 'Departement';
                case 'region':
                    return 'Région';
                case 'installationKit':
                    return 'Kit d\'installation';
                case 'total':
                    return 'Total';
            }
        },
        getDepartmentName: (dep) => {
            return 'Paris';
        },
        getRegionName: (region) => {
            return 'Ile-De-France';
        },
        getMaterialName: (material) => {
            return 'aluminum' ? 'Aluminium' : 'Plexiglass';
        },
        getVehicleType: (type) => {
            switch (type) {
                case 'auto':
                    return 'Voiture, camion, ...';
                case 'moto':
                    return 'Moto, scooter, quad';
            }
        },
        getCustomizationName: (type, customText = '') => {
            switch (type) {
                case 'none':
                    return '-';
                case 'rcg-logo':
                    return 'Logo RCG';
                case 'text':
                    return 'Texte personnalisé : ' + customText;
            }//
        }
    };

    $scope.getDocumentFileType = (url) => {
        if (!url)
            return null;
        const ext = url.toLowerCase().substr(-4, 4).replace('.', '');
        if (ext === 'pdf') {
            return 'pdf';
        } else if (['png', 'jpg', 'jpeg'].indexOf(ext) > -1) {
            return 'image';
        }
        return 'unknown';
    };

    $scope.showAddEditAddressPopup = ($event, address) => {
        if (!address || !address._id) {
            address = {
                title: $scope.client.title,
                firstname: $scope.client.firstname,
                lastname: $scope.client.lastname
            };
        }
        //const originalObj = angular.copy(address);
        $mdDialog.show({
            templateUrl: 'app/clients/address.html',
            parent: angular.element(document.body),
            targetEvent: $event,
            clickOutsideToClose: false,
            fullscreen: true,
            locals: {
                client: $scope.client,
                address: address
            },
            controller: ($scope, $mdDialog, client, address) => {
                $scope.addressTypes = [
                    {text: 'Par défaut', value: 'common'},
                    {text: 'Facturation', value: 'billing'},
                    {text: 'Livraison', value: 'shipping'}
                ];
                $scope.options = {
                    types: 'address',
                    country: 'fr'
                };
                $scope.selectedAddress = '';
                $scope.$on('mapentrySelected', function (details) {
                    try {
                        const address = details.targetScope.details.address_components;
                        if (address && Array.isArray(address)) {
                            let addressLine1 = '';
                            for (var i = 0; i < address.length; i++) {
                                const component = address[i];
                                if (component.types.indexOf('street_number') > -1 ||
                                        component.types.indexOf('route') > -1) {
                                    addressLine1 += ' ' + component.long_name;
                                } else if (component.types.indexOf('locality') > -1) {
                                    $scope.address.city = component.long_name;
                                } else if (component.types.indexOf('postal_code') > -1) {
                                    $scope.address.postalCode = component.long_name;
                                }
                            }
                            $scope.address.addressLine1 = addressLine1.trim();
                        }
                    } catch (e) {
                    }
                });
                $scope.edit = typeof address._id !== 'undefined';
                $scope.address = address;
                $scope.client = client;
                const originalObj = angular.copy(address);
                $scope.closeDialog = (validated = false, form = null) => {
                    if (!$scope.edit) { // adding a new address
                        if (!validated) { // cancel button is clicked
                            $mdDialog.cancel();
                        } else {
                            if (!form.$valid) {
                                return;
                            }
                            $mdDialog.hide(clients.addAddress($scope.client, address));
                        }
                        return;
                    }
                    const isChanged = !angular.equals($scope.address, originalObj);
                    if (!validated) {
                        if (isChanged) {
                            for (let attr in originalObj) {
                                $scope.address[attr] = originalObj[attr];
                            }
                        }
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid) {
                            return;
                        }
                        if (isChanged) {
                            $mdDialog.hide(clients.updateAddress($scope.client, address));
                        } else {
                            $mdDialog.hide();
                        }
                }
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('L\'adresse "' + address.name + '" a bien été ' +
                        (address._id ? 'mise à jour' : 'ajoutée') + '.', 'success');
                if (!address._id && res) {
                    $scope.client.Addresses.unshift(res);
                }
            } else {
            }
        }).catch((err) => {
            if (err) {
                //$scope.items[index] = originalObj;
                toast.show(err && err.message ? err.message
                        : 'Une erreur est survenue lors de traitement de votre demande',
                        'error', true);
            }
        });
    };

    $scope.showDeleteAddressConfirm = ($event, address, index) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer l'adresse "<b>` + address.name + `</b>" ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                address: client,
                index: index
            },
            controller: ($scope, $mdDialog, toast, clients, address) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        clients.deleteAddress(address).then(() => {
                            toast.show('L\'adresse "' + address.name + '" a bien été supprimée.', 'success');
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                        });
                    }//
                };
            }
        });
    };

    $scope.showDeleteClientConfirm = ($event, client) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer le compte client "<b>` + client.name + `</b>" ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                client: client
            },
            controller: ($scope, $mdDialog, toast, clients, client) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        clients.delete(client).then(() => {
                            toast.show('Le compte client "' + client.name + '" a bien été supprimé.', 'success');
                            $location.path('/clients');
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                        });
                    }//
                };
            }
        });
    };

    $scope._checkDocument = ($event, document, accepted = true) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="column">
                        <div>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>
                                    assignment_turned_in
                                </md-icon>
                                Confirmation
                            </h3>
                        </div>
                        <md-divider></md-divider>
                        <br>
                        <span>
                            Voulez-vous confirmer` +
                    (accepted ? ' l\'acceptation ' : ' le refus ') +
                    ' de ce document :<br><br><h4><b><center>' +
                    document.label + '</center></b></h4>' +
                    `</span><br>` +
                    (accepted ? '' :
                            `<md-input-container class="md-block" 
                                style='background-color: #f7f7f7; padding: 10px; 
                                    border: 1px solid #e0e0e0; border-radius: 5px;'>
                                <label>Commentaire / message</label>
                                <textarea ng-model="document.comment" 
                                    md-maxlength="255" rows="3"></textarea>
                            </md-input-container>
                            <!--<md-input-container>
                                <md-checkbox class="md-primary" ng-model="document.sendMessage">
                                    Envoyé ce commentaire / message au client.
                                </md-checkbox>
                            </md-input-container>-->`
                            ) +
                    `</md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                accepted: accepted,
                document: document
            },
            controller: ($scope, $mdDialog, toast, documents, accepted, document) => {
                $scope.document = document;
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm)
                        documents.check($scope.document, accepted).then((updatedDocument) => {
                            toast.show('Le document "' + $scope.document.label +
                                    '" a bien été marqué comme ' +
                                    (accepted ? 'accepté' : 'refusé') + ' .', 'success');
                            $scope.document.status = updatedDocument.status;
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message :
                                    DEFAULT_ERROR_MESSAGE);
                        });
                };
            }
        });
    };

    $scope.activateAccount = (client) => {
        clients.activateAccount(client).then(() => {
            toast.show('Le compte a bien été activé.', 'success');
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    DEFAULT_ERROR_MESSAGE);
        });
    };

    $scope.notify = (procedure, type = null) => {
        procedureService.notify(procedure._id, type).then((result) => {
            toast.show('Une notification de relance pour le téléversement des documents ' +
                    'a bien été envoyée au client.', 'success');
        }).catch((err) => {
            toast.show(err && err.message ? err.message :
                    DEFAULT_ERROR_MESSAGE);
            console.error(err);
        });
    };

    function getClients() {
        $scope.params = [];
        const _filters = Object.assign(
            {},
            $scope.fixedFilter || {},
            $scope.pagination.filters || {}
        )
        if (_filters) {
            for (let i in _filters) {
                if (i === 'date') {
                    $scope.params[i] = moment(_filters[i]).format(config.date.defaultFormat);
                } else {
                    $scope.params[i] = _filters[i];
                }
            }
        }
        clients.get($scope.params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.clients = data.items;

            for (let i = 0; i < $scope.clients.length; i++) {
                $scope.client = $scope.clients[i];
            }
            $scope.pagination.totalItems = data.count;
            $scope.$apply();

        });
    }

    function getProcedures() {
        $scope.getProcedures();
    }

    $scope.getProcedures = () => {

        let clientId = $scope.client._id;
        let f = $scope.clientProceduresPagination.filters;
        let cp = $scope.clientProceduresPagination.currentPage;
        let ipp = $scope.clientProceduresPagination.itemsPerPage;

        $scope.params = [];
        if (f) {
            let filters = f;
            for (let i in filters) {
                if (i === 'date') {
                    $scope.params[i] = moment(filters[i]).format(config.date.defaultFormat);
                } else {
                    $scope.params[i] = filters[i];
                }
            }
        }

        clients.getProcedures(clientId, $scope.params, cp, ipp).then((data) => {
            for (let i = 0; i < data.items.length; i++) {
                let procedure = data.items[i];
                procedure.origin = procedureTools.getOrigin(procedure);
                procedure.status = procedureTools.getStatus(procedure);
            }
            $scope.client.procedures = data.items;
            $scope.clientProceduresPagination.totalItems = data.count;
            $scope.$apply();
        });
    };

    $scope.generatePassword = () => {
        if ($scope.client === null) {
            $scope.client = {};
        }
        $scope.client.password = passwords.generate({
            passwordLength: 8,
            uppercase: true,
            numbers: true,
            specials: true,
            similarChars: false
        });
    };

    function updateInfos(type) {
        if (type === 'company') {

        }
    }

    $scope.updatePersonInfos = () => {
        let data = {};
        let client = $scope.client;
        let oClient = $scope.originalClient;
        if (client.type !== oClient.type) {
        } else {
            data = {
                _id: client._id
            };
            if (client.title !== oClient.title) {
                data.title = client.title;
            }
            if (client.lastname !== oClient.lastname) {
                data.lastname = client.lastname;
            }
            if (client.firstname !== oClient.firstname) {
                data.firstname = client.firstname;
            }
            if (Object.keys(data).length > 1) {
                updateClient(data);
            }
        }
    };
    $scope.updateCompanyInfos = () => {
        let data = {};
        let client = $scope.client;
        let oClient = $scope.originalClient;
        // change account
        if (client.type !== oClient.type) {
            if (client.type === 'client-particulier') {
                toast.show("La conversion vers un compte du particulier est impossible.", 'error', true);
            } else {
                let msg = '';
                if (typeof client.company !== 'string' || client.company.length < 2) {
                    msg = 'La raison sociale est requise.';
                }
                if (typeof client.siren !== 'string' || client.company.siren < 9) {
                    msg += (msg !== '' ? '<br>' : '') + 'Le N° SIREN/T est requis.';
                }
                if (msg !== '') {
                    toast.show(msg, 'error', true);
                    return;
                }
                data = {
                    _id: client._id,
                    type: client.type,
                    siren: client.siren,
                    company: client.company
                };
                updateClient(data);
            }
        } else { // juste updating infos
            data = {
                _id: client._id,
                type: client.type,
                siren: client.siren,
                company: client.company
            };
            updateClient(data);
        }

    };
    $scope.updateContacts = () => {
        let data = {};
        let client = $scope.client;
        let oClient = $scope.originalClient;
        data = {
            _id: client._id
        };
        if (client.email !== oClient.email) {
            data.email = client.email;
        }
        if (client.phone !== oClient.phone) {
            data.phone = client.phone;
        }
        if (Object.keys(data).length > 1) {
            updateClient(data);
        }
    };
    $scope.updateAddress = () => {
        let data = {};
        let address = $scope.client.address;
        let oAddress = $scope.originalClient.address;
        let fields = [
            '_id', 'city', 'ext', 'line2', 'line3', 'num', 'postalCode',
            'wayName', 'wayType'
        ];
        for (let i = 1; i < fields.length; i++) {
            let field = fields[i];
            let val = oAddress[field] + '', newVal = address[field] + '';
            if (val !== newVal) {
                data[field] = newVal;
            }
        }
        if (Object.keys(data).length > 0) {
            data._id = address._id;
            updateClient({
                address: data,
                _id: $scope.client._id
            });
        }
    };

    function updateClient(data) {
        console.log('updateClient');
        clients.update(data).then(() => {
            toast.show('Le compte a bien été mis à jour.', 'success', true);
            $scope.originalClient = Object.assign({}, $scope.client);
        }).catch((err) => {
            console.log(err);
            toast.show(err, 'error');
        });
    }

    $scope.submitForm = (form) => {
        let client = $scope.client;
        if (form.$valid) {
            let promise = $scope.isEdit ? clients.update(client) : clients.add(client);
            promise.then(() => {
                let msg = 'Le compte a bien été ' + ($scope.isEdit ? 'mis à jour' : 'ajouté') + '.';
                toast.show(msg, 'success', true).then(() => {
                    if (!$scope.isEdit) {
                        $scope.firstRun = true;
                        $location.path(`/clients/${$scope.getClientType()}`);
                    }
                });
            }).catch((err) => {
                toast.show(err, 'error');
            });
        } else {
            toast.show('Veuillez remplir tous les champs obligatoires.', 'error', true);
        }
    };

    $scope.noter = (clientId) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="column">
                        <div>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>
                                    note_add
                                </md-icon>
                                Notes
                            </h3>
                        </div>
                        <md-divider></md-divider>
                        <br>
                        <span>
                            Voulez-vous laisser des note ?
                        </span><br>
                        <md-input-container class="md-block" 
                                style='background-color: #f7f7f7; padding: 10px; 
                                    border: 1px solid #e0e0e0; border-radius: 5px;'>
                                <label>Noter </label>
                                <textarea ng-model="note" 
                                    md-maxlength="1000" rows="3"></textarea>
                            </md-input-container>
                        </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Envoyer</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            controller: ($scope, $mdDialog, toast) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        procedureService.noter(clientId, $scope.note).then(() => {
                            toast.show('Note bien enregistré', 'success');
                            $route.reload();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    }//
                };
            }
        });

    };

//---------------------------------------------------------------- documents ---

    $scope.showAddDocumentPopup = (clientId = null) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            templateUrl: TEMPLATES_DIR + 'add.document.html',
            locals: {
                clientId: $scope.client._id || clientId,
                documentTypes: $scope.documentTypes
            },
            controller: ($scope, $mdDialog, toast, procedureTools, clients, clientId, documentTypes) => {

                $scope.documentTypes = documentTypes;
                $scope.documentSubTypes = [];
                $scope.selectedDocumentType = null;
                $scope.selectedDocumentSubType = null;
                $scope.valid = false;
                $scope.subTypeRequired = true;

                $scope.onDocumentTypeSelect = ($event, subTypeSelect) => {
                    if (!subTypeSelect) {
                        $scope.documentSubTypes = null;
                        $scope.selectedDocumentSubType = null;
                        let selectedDocumentType = JSON.parse($scope.selectedDocumentType);
                        if (selectedDocumentType.hasSubTypes === true) {
                            $scope.valid = false;
                            $scope.subTypeRequired = true;
                            $scope.documentSubTypes = procedureTools.getDocumentTypes(selectedDocumentType.name);
                        } else {
                            $scope.valid = true;
                            $scope.subTypeRequired = false;
                        }
                    } else {
                        if ($scope.selectedDocumentSubType !== null) {
                            $scope.valid = true;
                            $scope.subTypeRequired = false;
                        } else {
                            $scope.subTypeRequired = true;
                            $scope.valid = false;
                        }
                    }
                    $timeout(() => {
                        $scope.$apply();
                    });
                };

                $scope.closeDialog = (confirm = false) => {
                    if (confirm) {
                        let documentType = JSON.parse($scope.selectedDocumentType);
                        let documentSubType = $scope.selectedDocumentSubType !== null ? JSON.parse($scope.selectedDocumentSubType) : null;
                        clients.addDocument(clientId, {
                            type: documentType.name,
                            subType: documentSubType && documentSubType.name !== documentType.name ? documentSubType.name : null,
                            field: documentSubType ? documentSubType.name : documentType
                        }).then((res) => {
                            let msg = 'Le document à bien été ajouté à la liste des documents demandés.';
                            let timeout = 3000;
                            if (res.message) {
                                timeout = 5000;
                                msg = res.message;
                            } else {
                                $mdDialog.hide();
                                //$route.reload();
                            }
                            toast.show(msg, 'success', true, timeout);
                        }).catch((err) => {

                            console.log('>>> error :');
                            console.log(err);

                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error', true, 30000);
                        });
                    } else {
                        $mdDialog.hide();
                    }//
                };
            }
        });
    };

    $scope.showUploadDocumentPopup = (document) => {
        $mdDialog.show({
            templateUrl: TEMPLATES_DIR + 'upload.document.html',
            clickOutsideToClose: false,
            locals: {
                document: document,
                clientId: $scope.client._id
            },
            controller: ($scope, $mdDialog, clients, clientId, document) => {

                $scope.docToUpload = document;
                $scope.clientId = clientId;
                $scope.document = null;

                $scope.onDocumentFieldSelect = (field) => {
                    $scope.docToUpload.field = field;
                    $scope.docToUpload.type = field;
                };
                $scope.onFileSelect = (files, target) => {
                    let file = files[0];
                    if (!file) {
                        return;
                    }
                    $scope.$apply(function () {
                        $scope.document = files[0];
                    });
                };
                $scope.closeDialog = (validated = false) => {
                    if (!validated) {
                        $mdDialog.cancel();
                    } else {
                        let formData = new FormData();
                        formData.append($scope.docToUpload.field, $scope.document);
                        clients.uploadDocument($scope.clientId, $scope.docToUpload._id, formData, $scope.docToUpload.type).then((res) => {
                            $mdDialog.cancel();
                            toast.show('Le document a bien été téléversé.', 'success');
                            //$location.search('tab', 2);
                            $route.reload();
                        }).catch((err) => {
                            console.error('upload document error :');
                            console.log(err);
                            toast.show(err, 'error', true);
                        });
                    }//
                };
            }
        });
    };

    $scope.acceptDocument = (document) => {
        let client = $scope.client;
        let confirm = $mdDialog.confirm()
                .title('Accepter un document')
                .textContent('Voulez-vous vraiment accepter ce document ?')
                .targetEvent(event)
                .ok('Oui')
                .cancel('Non')
                .theme('confirm');
        $mdDialog.show(confirm).then(() => {
            clients.acceptDocument(client._id, document._id).then(() => {
                toast.show('Le document a bien été accepté.', 'success');
                //$location.search('tab', 2);
                $route.reload();
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }, () => {
            // cancelled
        });
    };

    $scope.destroyDocument = (documentId) => {
        let client = $scope.client;
        $mdDialog.show({
            parent: angular.element(documentId.body),
            templateUrl: TEMPLATES_DIR + 'delete.document.html',
            controller: ($scope, $mdDialog, toast) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        documents.delete(documentId, client._id).then(() => {
                            toast.show('Le document a bien été supprimé.', 'success');
                            $route.reload();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
                        });
                    }//
                };
            }
        });
    };

    function prepareDocuments() {
        for (let i = 0; i < $scope.client.documents.length; i++) {
            let document = $scope.client.documents[i];
            document.typeLabel = procedureTools.getDocumentTypeLabel(document);
            document.isType = document.subType === null;
            document.isOK = procedureTools.isDocOK(document);
            document.icon = procedureTools.getDocumentFileTypeIcon(document);
            document.url = config.websiteURL/*'https://v1.api.reflexecg.eu' /**/ + '/' + document.path;

            document.fileType = procedureTools.getDocumentFileType(document);
            document.isToUpload = true;
            document.isUploaded = document.status === 'document-uploaded';
            document.isAccepted = document.status === 'document-accepted';
            document.isRejected = document.status === 'document-rejected';
            document.isChecked = document.isAccepted || document.isRejected;
            if (document.isToUpload) {
                document.isRecto = document.field.indexOf('recto') > -1;
                document.isVerso = document.field.indexOf('verso') > -1;
                document.isRectoVerso = document.isRecto === true || document.isVerso === true;
            }
            let docStatus = document.Status.name;
            document.Status.name = docStatus[0].toUpperCase() + docStatus.slice(1);
        }
    }

    $scope.getDocuments = () => {
        let client = $scope.client;
        clients.getDocuments(client._id).then((documents) => {
            $scope.client.documents = documents;
            prepareDocuments();
            console.log('>>> documents');
            console.log(documents);
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
        });
    };

    $scope.createPrepaidAccount = () => {
        let client = $scope.client;
        clients.createPrepaidAccount(client._id, {
            balance: 15000
        }).then(() => {
            toast.show('Le compte prépayé a bien été créé.', 'success');
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
        });
    };

    $scope.savePassword = () => {
        clients.updatePassword($scope.client._id, {password: $scope.client.password}).then(() => {
            toast.show('Le mot de passe a bien été mis à jour.', 'success');
        }).catch((err) => {
            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
        });
    };

//------------------------------------------------------------------------------

    switch (path) {
        case '/clients':
            getClients();
            break;
        case '/clients/add':
            break;
        case '/clients/edit':
            getClient($scope.clientId, true);
            break;
        case '/clients/partenaire':
            getClients();
            break;
        default:
            setTimeout(() => {
                getClient($scope.clientId);
            });
    }

});
